<template>
  <div id="ExistingIdChartSegment" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "ExistingIdChartSegment",
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    selectedYear: {
      type: [String, Number],
      default: null,
    },
    selectedYearSegment: {
      type: [String, Number],
      default: null,
    },
    selectedSegments: {
      type: Object,
      default: null,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      noDataLabel: null, // Initialize noDataLabel to null
      colors: ["#2fbf56", "#ff6b6b", "#4ecdc4", "#45b7d1", "#f7a072", "#588da8"], // Example colors
    };
  },
  watch: {
    chartData: {
      handler(newData) {
        console.log("Received chartData:", newData);
        this.updateChart(newData); // Update the chart with the new data
      },
      immediate: true,
    },
  },
  mounted() {
    console.log("Creating Segment Chart");
    this.createChart(this.chartData); // Create the chart when the component is mounted
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose(); // Ensure the chart is destroyed when the component is destroyed
      this.chart = null; // Clear the reference to avoid issues when re-creating the chart
    }
  },
  methods: {
    createChart(data) {
      console.log("Creating chart with data:", data);
      this.chart = am4core.create("ExistingIdChartSegment", am4charts.XYChart);
      this.chart.data = data; // Use the chartData passed as props

      // Configure the category axis (X-axis)
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.grid.template.location = 0;

      // Configure the value axis (Y-axis)
      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;

      // Add the "no data" label once during chart creation
      this.noDataLabel = this.chart.tooltipContainer.createChild(am4core.Label);
      this.noDataLabel.html = `<p style="font-weight: bold; font-size: 1.5rem;">No Data Available.</p><p style="margin-top: -0.5rem">Please choose another time or option</p>`;
      this.noDataLabel.align = "center";
      this.noDataLabel.valign = "middle";
      this.noDataLabel.dy = -20;
      this.noDataLabel.horizontalCenter = "middle";
      this.noDataLabel.verticalCenter = "middle";
      this.noDataLabel.hide(); // Start hidden

      // Create series for each segment
      Object.keys(data[0]).forEach((key) => {
        if (key !== "month") {
          this.createSeries(this.chart, key, key); // Create a series for each key
        }
      });

      this.chart.cursor = new am4charts.XYCursor();
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.position = "bottom";
    },
    updateChart(newData) {
      console.log("Updating chart with data:", newData);

      if (!this.chart) {
        console.error("Chart is not initialized");
        return; // Exit if chart is not initialized
      }

      if (!newData || newData.length === 0) {
        if (this.noDataLabel) {
          this.noDataLabel.show(); // Show the "no data" label if there's no data
        }
        return;
      }

      if (this.noDataLabel) {
        this.noDataLabel.hide(); // Hide the label if there is data
      }

      // Clear existing series
      this.chart.series.clear();

      // Create new series based on the new data
      Object.keys(newData[0]).forEach((key) => {
        if (key !== "month" && key !== "color") {
          // Skip the month and color fields
          let series = this.chart.series.push(new am4charts.LineSeries());
          series.dataFields.valueY = key;
          series.dataFields.categoryX = "month";
          series.name = key;
          series.strokeWidth = 2;
          series.minBulletDistance = 10;
          series.tooltipText = "{name}: [bold]{valueY}[/]";
          series.tooltip.pointerOrientation = "vertical";

          // Assign color from data if available, otherwise use a random color
          const colorIndex = newData.findIndex((item) => item[key] !== undefined);
          const segmentColor = newData[colorIndex]?.color || this.colors[Math.floor(Math.random() * this.colors.length)];

          series.stroke = am4core.color(segmentColor); // Use color from data
          series.fill = series.stroke;
        }
      });

      // Update the chart data
      this.chart.data = newData;
      this.chart.invalidateData(); // Refresh chart data
    },
    createSeries(chart, field, name) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "month";
      series.name = name;
      series.strokeWidth = 2;
      series.minBulletDistance = 10;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.tooltip.pointerOrientation = "vertical";
    },
  },
};
</script>

<style>
#ExistingIdChartSegment {
  width: 100%;
  height: 500px;
}
</style>

<template>
  <div>
    <div class="row mt-2 mb-3">
      <div class="col-md-6 row" style="padding-top: 6px">
        <div class="col mt-auto mb-auto" style="padding-right: 0">
          <h4 class="mb-0">Replacement</h4>
        </div>
      </div>
      <div class="col-md-6 row ml-auto justify-content-end">
        <div class="col-6">
          <div class="filter-date icon fa-calendar">
            <flat-pickr v-model="dateRange.date" :config="rangeConfig" class="mt-3 mb-3" placeholder="Select Date" />
          </div>
        </div>
        <div class="col-4">
          <Multiselect v-model="selectedYear" :options="yearOptions" placeholder="All Year"></Multiselect>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-8">
        <div class="card d-flex flex-column relative replacement-production-year">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <h5 class="card-title mb-0 mr-2">Replacement</h5>
              <div class="tooltip-container">
                <span><i class="fas fa-info-circle"></i></span>
                <div class="tooltip">
                  <div class="tooltip-title">Replacement</div>
                  <div class="tooltip-content">Kendaraan yang melakukan pergantian QR di H2</div>
                </div>
              </div>
              <div class="d-flex ml-auto">
                <div class="d-flex ml-auto">
                  <Multiselect v-model="selectedChartDealer" class="replacement-column-dealer mt-1"
                    :options="chartDealerOptions" track-by="loc_code" label="loc_code" placeholder="All Dealers"
                    @input="onChartDealerChange" style="width: 200px"></Multiselect>
                  <button class="btn btn-outline-secondary ml-2" @click="downloadReplacement()"
                    style="width: 200px; height: 40px; margin-top: 4px">
                    Download CSV
                    <i class="bi bi-download"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="isLoadingStacked"
              class="loading text-center h-100 d-flex align-items-center justify-content-center">
              <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
            </div>
            <replacement-stacked-column-chart v-else-if="replacementData" :start-date="dateRange.start"
              :end-date="dateRange.end" :selected-year="selectedYear"
              :selected-chart-dealer="selectedChartDealer"></replacement-stacked-column-chart>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card d-flex flex-column relative replacement-production-year">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <h6 class="card-title mb-0 mr-2">Overall by Production Year</h6>
              <div class="tooltip-container">
                <span><i class="fas fa-info-circle"></i></span>
                <div class="tooltip">
                  <div class="tooltip-content">Presentase data new ID berdasarkan tahun produksi</div>
                </div>
              </div>
              <div class="d-flex ml-auto">
                <button class="btn btn-outline-secondary ml-1 mt-1" @click="downloadOverall()"
                  style="width: 150px; height: 40px">
                  Download CSV
                  <i class="bi bi-download"></i>
                </button>
              </div>
            </div>
            <div v-if="isLoadingOverall"
              class="loading text-center h-100 d-flex align-items-center justify-content-center">
              <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
            </div>
            <replacement-production-year-pie-chart v-else-if="replacementData" :start-date="dateRange.start"
              :end-date="dateRange.end" :selected-year="selectedYear"></replacement-production-year-pie-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-4 relative">
      <div class="card-body">
        <div class="d-flex align-items-center mb-3">
          <h5 class="card-title mb-0 mr-2">Segment</h5>
          <div class="tooltip-container">
            <span><i class="fas fa-info-circle"></i></span>
            <div class="tooltip">
              <div class="tooltip-title">Segment</div>
              <div class="tooltip-content">Kendaraan yang melakukan pergantian QR di H2 dilihat berdasarkan segmentasi
                motor</div>
            </div>
          </div>
          <div class="d-flex ml-auto">
            <Multiselect class="mt-1 ml-2" v-model="selectedSegments" :options="segmentOptions" :multiple="true"
              placeholder="Select Segments" @input="fetchSegmentData" style="width: 200px"></Multiselect>
            <Multiselect class="mt-1 ml-2" v-model="segmentSelectedYear" :options="yearOptions" placeholder="All Year"
              @input="fetchSegmentData" style="width: 200px"></Multiselect>
            <button class="btn btn-outline-secondary ml-2" @click="downloadSegment()"
              style="width: 200px; height: 40px; margin-top: 4px">
              Download CSV
              <i class="bi bi-download"></i>
            </button>
          </div>
        </div>
        <div v-if="isLoadingSegment" class="loading text-center h-100 d-flex align-items-center justify-content-center">
          <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
        </div>
        <replacement-segment-line-chart ref="segmentChart" :start-date="dateRange.start" :end-date="dateRange.end"
          :selected-segments="selectedSegments"></replacement-segment-line-chart>
      </div>
    </div>

    <div class="card mb-4 relative">
      <div class="card-body">
        <div class="d-flex align-items-center mb-3">
          <h5 class="card-title mb-0 mr-2">Replacement Reasons</h5>
          <div class="tooltip-container">
            <span><i class="fas fa-info-circle"></i></span>
            <div class="tooltip">
              <div class="tooltip-title">Replacement Reasons</div>
              <div class="tooltip-content">Alasan pergantian qr yang terjadi di H2</div>
            </div>
          </div>
          <div class="d-flex ml-auto">
            <Multiselect class="mt-1 ml-2" v-model="replacementReasonYear" :options="yearOptions" placeholder="All Year"
              @input="fetchReplacementReasonsData" style="width: 200px"></Multiselect>
            <button class="btn btn-outline-secondary ml-2" @click="downloadReplacementReasons()"
              style="width: 200px; height: 40px; margin-top: 4px">
              Download CSV
              <i class="bi bi-download"></i>
            </button>
          </div>
        </div>
        <div v-if="isLoadingReasons" class="loading text-center h-100 d-flex align-items-center justify-content-center">
          <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
        </div>
        <replacement-reasons v-else-if="replacementData" ref="replacementReasons" :start-date="dateRange.start"
          :end-date="dateRange.end"></replacement-reasons>
      </div>
    </div>

    <div class="card mb-4 relative card-table">
      <div class="card-body">
        <div class="d-flex align-items-center mb-3">
          <h5 class="card-title mb-0 mr-2">Replacement ID by Region</h5>
          <div class="tooltip-container">
            <span><i class="fas fa-info-circle"></i></span>
            <div class="tooltip">
              <div class="tooltip-title">Replacement ID by Region</div>
              <div class="tooltip-content">Kendaraan yang melakukan pergantian QR di H2 dilihat berdasarkan lokasi
                region</div>
            </div>
          </div>
          <div class="d-flex ml-auto">
            <Multiselect class="mt-1 ml-2" v-model="selectedRegion" :options="regionOptions" placeholder="Semua MD"
              style="width: 200px"></Multiselect>
            <Multiselect class="mt-1 ml-2" v-model="selectedManufactureYear" :options="yearOptions"
              placeholder="All Year" @input="fetchReplacementData" style="width: 200px"></Multiselect>
            <button class="replacement-download btn btn-outline-secondary ml-2" @click="downloadRegion()"
              style="width: 200px; height: 40px; margin-top: 4px">
              Download CSV
              <i class="bi bi-download"></i>
            </button>
          </div>
        </div>
        <div v-if="isLoadingRegion" class="loading text-center h-100 d-flex align-items-center justify-content-center">
          <img src="/img/loading.gif" width="48" height="48" alt="Loading Replacement" />
        </div>
        <div v-else>
          <table class="table table-hover" v-if="replacementData && Object.keys(replacementData).length > 0">
            <thead>
              <tr>
                <th class="hso-table-header" style="background-color: #ffffff">Region Name</th>
                <th v-for="month in displayedMonths" :key="month" class="hso-table-header"
                  style="background-color: #ffffff">
                  {{ month }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(regionData, regionName) in paginatedData" :key="regionName">
                <td class="hso-table-body" style="border-bottom: none; border-top: none">{{ regionName }}</td>
                <td v-for="month in displayedMonths" :key="month" class="hso-table-body"
                  style="border-bottom: none; border-top: none">
                  {{ getValueForMonth(regionData, month) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center py-8">
            <p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
            <p style="margin-top: -0.5rem">Please choose another time or option</p>
          </div>
        </div>

        <!-- Pagination -->
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div>Showing {{ paginationStart + 1 }} to {{ paginationEnd }} of {{ totalItems }} entries</div>
          <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="rowsPerPage"
            aria-controls="replacement-table"></b-pagination>
        </div>
      </div>
    </div>

    <b-card class="mb-4 relative card-table">
      <div class="d-flex align-items-center mb-3">
        <h5 class="card-title mb-0 mr-2">Replacement ID by Dealer</h5>
        <div class="tooltip-container">
          <span><i class="fas fa-info-circle"></i></span>
          <div class="tooltip">
            <div class="tooltip-title">Replacement ID by Dealer</div>
            <div class="tooltip-content">Kendaraan yang melakukan pergantian QR di H2 dilihat berdasarkan lokasi dealer
            </div>
          </div>
        </div>
        <div class="d-flex ml-auto">
          <Multiselect class="mt-1 ml-2" v-model="dealerSelectedYear" :options="yearOptions" placeholder="All Year"
            @input="fetchDealerReplacementData" style="width: 200px;"></Multiselect>
          <Multiselect class="mt-1 ml-2" v-model="selectedDealer" :options="dealerOptions" placeholder="All Dealer"
            style="width: 200px"></Multiselect>
          <button class="region-download btn btn-outline-secondary ml-2" @click="downloadDealerExcel"
            style="width: 200px; height: 40px; margin-top: 4px">
            Download CSV
            <i class="bi bi-download"></i>
          </button>
        </div>
      </div>
      <div v-if="isLoadingDealer" class="loading text-center h-100 d-flex align-items-center justify-content-center">
        <img src="/img/loading.gif" width="48" height="48" alt="Loading Replacement" />
      </div>
      <div v-else>
        <div v-if="!dealerReplacementData || Object.keys(dealerReplacementData).length === 0" class="text-center py-8">
          <p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
          <p style="margin-top: -0.5rem">Please choose another time or option</p>
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th class="hso-table-header" style="background-color: #ffffff">Dealer Name</th>
                <!-- Show all columns only when no specific year is selected -->
                <template v-if="!dealerSelectedYear || dealerSelectedYear === 'All Year'">
                  <th v-for="year in yearOptions" :key="year" class="hso-table-header"
                    style="background-color: #ffffff">
                    {{ year }}
                  </th>
                </template>
                <!-- Show only selected year column -->
                <template v-else>
                  <th class="hso-table-header" style="background-color: #ffffff">
                    {{ dealerSelectedYear }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-if="Object.keys(dealerPaginatedData).length === 0">
                <td :colspan="getColumnCount" class="text-center py-8">
                  <p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
                  <p style="margin-top: -0.5rem">Please choose another time or option</p>
                </td>
              </tr>
              <tr v-else v-for="(dealerData, dealerName) in dealerPaginatedData" :key="dealerName">
                <td class="hso-table-body" style="border-bottom: none; border-top: none">{{ dealerName }}</td>
                <!-- Show all year columns when no specific year is selected -->
                <template v-if="!dealerSelectedYear || dealerSelectedYear === 'All Year'">
                  <td v-for="year in yearOptions" :key="year" class="hso-table-body"
                    style="border-bottom: none; border-top: none">
                    {{ getValueForYearColumn(dealerData, year) }}
                  </td>
                </template>
                <!-- Show only selected year column -->
                <template v-else>
                  <td class="hso-table-body" style="border-bottom: none; border-top: none">
                    {{ getValueForYearColumn(dealerData, dealerSelectedYear) }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>

          <!-- Pagination -->
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div class="hso-table-pagination">Showing {{ dealerPaginationStart + 1 }} to {{ dealerPaginationEnd }} of {{
              filteredDealerTotalItems }} entries</div>
            <b-pagination v-model="dealerCurrentPage" :total-rows="filteredDealerTotalItems"
              :per-page="dealerRowsPerPage" aria-controls="dealer-table"></b-pagination>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import qs from "qs";
import debounce from 'debounce';
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import Multiselect from "vue-multiselect";
import { FoldSpinner } from "vue-spinners";
import replacementStackedColumnChart from "./childComponents/replacementStackedColumnChart";
import replacementProductionYearPieChart from "./childComponents/replacementProductionYearPieChart";
import replacementSegmentLineChart from "./childComponents/replacementSegmentLineChart";
import replacementReasons from "./childComponents/replacementReasons";
import Tooltip from "./childComponents/Tooltip.vue";
import XLSX from "xlsx";

export default {
  name: "replacement",
  metaInfo: {
    title: "Replacement",
  },
  data() {
    return {
      data: null,
      options: {
        status: [
          { title: "Requested", value: 0 },
          { title: "Processing", value: 1 },
          { title: "Ready", value: 2 },
          { title: "Data Empty", value: 3 },
          { title: "Deleted", value: 4 },
        ],
        ...this.$store.state.filterData,
      },

      selectedChartDealer: null,
      chartDealerOptions: [],

      filter: {
        report: "",
        user: "",
        status: "",
      },

      // Top Navigation
      rangeConfig: {
        mode: "range",
        altInput: true,
        altInputClass: "form-control input elegant-flatpickr",
        dateFormat: "Y-m-d",
        onChange: this.onDateChange,
        defaultDate: null,
        date_filter: true,
        altFormat: "M j, Y",
        locale: {
          months: {
            shorthand: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            longhand: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          },
        },
      },
      dateRange: {
        date: null,
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      selectedYear: null, // This is for the main section
      yearOptions: ["2024", "2023", "2022", "<=2021", "Unknown"],

      isLoadingStacked: false,
      replacementData: {},
      months: ["January", "February", "March", "April", "May", "June", "July"],
      displayedMonths: [],

      currentPage: 1,
      rowsPerPage: 20,

      isLoadingReasons: false,
      replacementReasonYear: null,
      // replacementReasonYearOptions: ["2024", "2023", "2022", "<=2021",],

      isLoadingDealer: false,
      dealerReplacementData: {},
      dealerCurrentPage: 1,
      dealerRowsPerPage: 20,
      dealerSelectedYear: null, // This is for the Replacement by Dealer section
      selectedDealer: null,

      isLoadingSegment: false,
      segmentData: [],
      selectedSegments: [],
      segmentOptions: [],
      segmentSelectedYear: null, // This is for the Segment section
      // segmentYearOptions: ["2024", "2023", "2022", "<=2021"], // Adjust as needed

      isLoadingRegion: false,
      selectedRegion: null,
      regionOptions: [],
      selectedManufactureYear: null,
      // manufactureYearOptions: ["2024", "2023", "2022", "<=2021"],
    };
  },
  watch: {
    selectedYear(newYear) {
      // Update semua Multiselect tahun terkait
      this.segmentSelectedYear = newYear;
      this.replacementReasonYear = newYear;
      this.selectedManufactureYear = newYear;
      this.dealerSelectedYear = newYear;

      // Trigger debounced data fetching methods
      this.debouncedFetchAllData();
    },

    selectedDealer() {
      this.resetPagination();
    },
    selectedSegments(newSegments) { // Corrected from 'selectedSegment' to 'selectedSegments'
      this.fetchSegmentData();
    },
    replacementData: {
      handler(newData) { },
      immediate: true,
    },
    selectedRegion() {
      this.currentPage = 1;
      this.debouncedFetchReplacementData();
    },
  },
  components: {
    flatPickr,
    Multiselect,
    fold: FoldSpinner,
    replacementStackedColumnChart,
    replacementProductionYearPieChart,
    replacementSegmentLineChart,
    replacementReasons,
    Tooltip,
  },
  created() {
    this.debouncedFetchAllData = debounce(this.fetchAllData, 300);
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return Object.fromEntries(Object.entries(this.filteredReplacementData).slice(start, end));
    },
    totalItems() {
      return Object.keys(this.filteredReplacementData).length;
    },
    paginationStart() {
      return (this.currentPage - 1) * this.rowsPerPage;
    },
    paginationEnd() {
      return Math.min(this.paginationStart + this.rowsPerPage, this.totalItems);
    },

    dealerPaginatedData() {
      const start = (this.dealerCurrentPage - 1) * this.dealerRowsPerPage;
      const end = start + this.dealerRowsPerPage;
      return Object.fromEntries(Object.entries(this.filteredDealerData).slice(start, end));
    },
    dealerTotalItems() {
      return Object.keys(this.dealerReplacementData).length;
    },
    dealerPaginationStart() {
      return (this.dealerCurrentPage - 1) * this.dealerRowsPerPage;
    },
    dealerPaginationEnd() {
      return Math.min(this.dealerPaginationStart + this.dealerRowsPerPage, this.filteredDealerTotalItems);
    },
    dealerOptions() {
      return Object.keys(this.dealerReplacementData);
    },
    yearOptions() {
      return ["2024", "2023", "2022", "<=2021"];
    },
    filteredDealerData() {
      let filtered = { ...this.dealerReplacementData };

      if (Object.keys(filtered).length === 0) {
        return {};
      }

      if (this.selectedDealer) {
        filtered = {
          [this.selectedDealer]: filtered[this.selectedDealer],
        };
      }

      return filtered;
    },
    getColumnCount() {
      return (!this.dealerSelectedYear || this.dealerSelectedYear === 'All Year')
        ? this.yearOptions.length + 1  // +1 for dealer name column
        : 2; // dealer name + selected year
    },
    filteredDealerTotalItems() {
      return Object.keys(this.filteredDealerData).length;
    },

    filteredReplacementData() {
      if (!this.selectedRegion) {
        return this.replacementData;
      }
      return {
        [this.selectedRegion]: this.replacementData[this.selectedRegion],
      };
    },
  },
  mounted() {
    this.fetchReplacementData();
    this.fetchDealerReplacementData();
    this.fetchChartDealerOptions();
    this.fetchSegmentOptions();
    this.fetchSegmentData(); // Add this line to fetch segment data on mount
  },
  methods: {
    fetchAllData() {
      this.fetchReplacementData();
      this.fetchDealerReplacementData();
      this.fetchSegmentData();
      this.fetchReplacementReasonsData();
    },
    debouncedFetchReplacementData: debounce(function () {
      this.fetchReplacementData();
    }, 300),

    fetchChartDealerOptions() {
      axios
        .get("/api/master/regions")
        .then((response) => {
          if (response.data.status === "success") {
            this.chartDealerOptions = response.data.data.map((dealer) => ({
              loc_code: dealer.loc_code,
              name: dealer.name,
            }));
          } else {
            console.error("Error fetching chart dealer options:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching chart dealer options:", error);
        });
    },
    onChartDealerChange(selectedDealer) {
      // This method will be called when a dealer is selected for the chart
      // The chart component will react to the change in selectedChartDealer prop
      console.log("Selected dealer:", selectedDealer);
    },

    fetchReplacementData() {
      this.isLoadingRegion = true;
      const start = moment(this.dateRange.start).format("YYYY-MM-DD");
      const end = moment(this.dateRange.end).format("YYYY-MM-DD");

      let params = { start, end };

      if (this.selectedRegion) {
        params.region = this.selectedRegion.split(" - ")[0];
      }

      if (this.selectedManufactureYear && this.selectedManufactureYear !== "All Years") {
        params.manufacture_year = this.selectedManufactureYear;
      }

      this.$http
        .get("/api/distribution/replacement/by-region", { params })
        .then((response) => {
          if (response.data.status === "success") {
            this.replacementData = response.data.data || {};
            if (!params.region) {
              this.regionOptions = Object.keys(response.data.data || {});
            }
            this.currentPage = 1;
            this.updateDisplayedMonths();
          } else {
            this.replacementData = {};
            console.error("Error fetching data:", response.data.message);
          }
        })
        .catch((error) => {
          this.replacementData = {};
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.isLoadingRegion = false;
        });
    },
    updateDisplayedMonths() {
      if (Object.keys(this.replacementData).length > 0) {
        const firstRegionData = this.replacementData[Object.keys(this.replacementData)[0]];
        this.displayedMonths = firstRegionData
          .map((item) => item.bulan)
          .sort((a, b) => {
            const monthOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return monthOrder.indexOf(a) - monthOrder.indexOf(b);
          });
      }
    },

    onDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        this.dateRange.start = moment(selectedDates[0]).format("YYYY-MM-DD");
        this.dateRange.end = moment(selectedDates[1]).format("YYYY-MM-DD");
        this.fetchReplacementData();
        this.fetchDealerReplacementData();
        this.fetchSegmentData();
      }
    },

    getValueForMonth(regionData, month) {
      const monthData = regionData.find((item) => item.bulan === month);
      return monthData ? monthData.value.toLocaleString() : "-";
    },

    fetchReplacementReasonsData() {
      const params = {
        start: this.dateRange.start,
        end: this.dateRange.end,
      };

      if (this.replacementReasonYear && this.replacementReasonYear !== "All Years") {
        params.manufacture_year = this.replacementReasonYear;
      }

      this.$refs.replacementReasons.updateChart(params);
    },

    fetchDealerReplacementData() {
      if (this.dealerSelectedYear) {
        return; // Don't fetch if year is selected
      }

      this.isLoadingDealer = true;
      const start = moment(this.dateRange.start).format("YYYY-MM-DD");
      const end = moment(this.dateRange.end).format("YYYY-MM-DD");

      let params = { start, end };

      this.$http
        .get("/api/distribution/replacement/by-dealer", { params })
        .then((response) => {
          this.dealerReplacementData = {};

          if (response.data.status === "success") {
            if (response.data.data && Object.keys(response.data.data).length > 0) {
              this.dealerReplacementData = response.data.data;
            }
          }
          this.dealerCurrentPage = 1;
        })
        .catch((error) => {
          console.error("Error fetching dealer data:", error);
          this.dealerReplacementData = {};
        })
        .finally(() => {
          this.isLoadingDealer = false;
        });
    },
    getValueForYearColumn(dealerData, year) {
      if (!dealerData || !Array.isArray(dealerData)) {
        return "0";
      }

      if (year === "<=2021") {
        const olderYears = dealerData.filter(item =>
          parseInt(item.year_group) <= 2021 || item.year_group === "Older"
        );
        const total = olderYears.reduce((sum, item) => sum + item.value, 0);
        return total.toLocaleString();
      }

      const yearData = dealerData.find(item => item.year_group === year);
      return yearData ? yearData.value.toLocaleString() : "0";
    },
    getDealerValueForYear(dealerData, year) {
      if (!dealerData || !Array.isArray(dealerData)) return "0";
      const yearData = dealerData.find((item) => item.year_group === year);
      return yearData ? yearData.value.toLocaleString() : "0";
    },

    getDealerValueForOlderYears(dealerData) {
      if (!dealerData || !Array.isArray(dealerData)) return "0";
      const olderYears = dealerData.filter((item) =>
        parseInt(item.year_group) <= 2021 || item.year_group === "Older"
      );
      const total = olderYears.reduce((sum, item) => sum + item.value, 0);
      return total.toLocaleString();
    },

    showTooltip() {
      this.$refs.tooltip.showTooltip();
    },
    hideTooltip() {
      this.$refs.tooltip.hideTooltip();
    },

    async downloadOverall() {
      try {
        const url = "/api/distribution/replacement/by-production-year";

        const start = this.dateRange.start;
        const end = this.dateRange.end;
        let params = { start, end };

        if (this.selectedYear && this.selectedYear !== "null") {
          params.manufacture_year = this.selectedYear;
        }

        const res = await axios.get(url, {
          params: { ...params },
        });

        const response = res.data.data;

        const header = ["Year", "Value"];
        const rows = response.map(item => [item.year_group, item.value]);

        // Create a new workbook and add the data
        const wb = XLSX.utils.book_new();
        const wsData = [header, ...rows];
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Overall Data");

        // Generate XLSX file and download
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

        // Convert the binary string to an array buffer
        function s2ab(s) {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
          }
          return buf;
        }

        // Create a blob and download the file
        const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
        const link = document.createElement("a");
        const xlsxUrl = URL.createObjectURL(blob);
        link.href = xlsxUrl;
        link.download = `overall_data_production_by_year_replacement.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error fetching registration data:", error);
        alert("Error: " + error.message);
      }
    },

    async downloadReplacement() {
      try {
        const start = moment(this.dateRange.start).format("YYYY-MM-DD");
        const end = moment(this.dateRange.end).format("YYYY-MM-DD");

        let params = { start, end };

        if (this.selectedChartDealer) {
          params.dealer = this.selectedChartDealer.loc_code;
        }

        if (this.selectedYear && this.selectedYear !== "All Years") {
          params.manufacture_year = this.selectedYear;
        }

        console.log("Params for API request:", params);

        const apiUrl = "/api/distribution/replacement"; // Renamed this variable
        const res = await axios.get(apiUrl, { params });

        const data = res.data.data;
        console.log("API response data:", data);

        const months = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));

        // Prepare data for XLSX
        const worksheetData = [];

        // Add header row (first row)
        worksheetData.push(["Year Group", ...months]);

        // Determine which year groups are present in the data
        const yearGroups = new Set();
        Object.values(data).forEach((monthData) => {
          monthData.forEach((item) => yearGroups.add(item.year_group));
        });

        // Convert Set to Array and sort
        const years = Array.from(yearGroups).sort((a, b) => {
          if (a === "Older") return 1;
          if (b === "Older") return -1;
          return b - a;
        });

        // Add rows for each year group
        years.forEach((year) => {
          const row = [year];
          months.forEach((month) => {
            const monthData = data[month].find((item) => item.year_group === year);
            row.push(monthData ? monthData.value : 0);
          });
          worksheetData.push(row);
        });

        // Create a worksheet
        const ws = XLSX.utils.aoa_to_sheet(worksheetData);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Replacement Report");

        // Write the Excel file and trigger download
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([wbout], { type: "application/octet-stream" });

        const link = document.createElement("a");
        const fileUrl = URL.createObjectURL(blob); // Renamed this variable
        link.href = fileUrl;
        link.setAttribute("download", `replacement_report.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (error) {
        console.error("Error fetching replacement data:", error);
        alert("Error: " + error.message);
      }
    },

    async downloadSegment() {
      try {
        const params = {
          start: this.dateRange.start,
          end: this.dateRange.end,
        };

        if (this.selectedSegments.length > 0) {
          params.segment = this.selectedSegments.join(",");
        }

        if (this.segmentSelectedYear && this.segmentSelectedYear !== "All Year") {
          params.manufacture_year = this.segmentSelectedYear;
        }

        const res = await axios.get("api/distribution/replacement/by-segment", { params });
        const response = res.data.data;

        const modifiedData = Object.entries(response).flatMap(([month, items]) =>
          items.map((item) => ({
            Month: month,
            Segment: item.segment,
            Value: item.value,
          }))
        );

        // Convert data to XLSX format
        const worksheet = XLSX.utils.json_to_sheet(modifiedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate timestamp for filename
        const timestamp = moment().format("YYYY-MM-DD_HH-mm-ss");
        const filename = `data_Segment_${timestamp}.xlsx`;

        // Write the workbook as an XLSX file
        XLSX.writeFile(workbook, filename, { bookType: "xlsx" });

        console.log("Data downloaded successfully as XLSX");
      } catch (error) {
        console.error("Error fetching segment data:", error);
        alert("Error: " + error.message);
      }
    },

    async downloadReplacementReasons() {
      try {
        const url = "/api/distribution/replacement/reasons";
        const params = {
          start: this.dateRange.start,
          end: this.dateRange.end,
        };

        if (this.replacementReasonYear && this.replacementReasonYear !== "All Years") {
          params.manufacture_year = this.replacementReasonYear;
        }

        const res = await axios.get(url, { params });

        if (res.data && res.data.data) {
          const data = res.data.data;

          // Prepare the data for Excel
          const excelData = [];
          const months = Object.keys(data);
          const reasons = [...new Set(months.flatMap((month) => data[month].map((item) => item.reason_replacement)))];

          // Create header row
          const headerRow = ["Month", ...reasons];
          excelData.push(headerRow);

          // Populate data rows
          months.forEach((month) => {
            const row = [month];
            reasons.forEach((reason) => {
              const item = data[month].find((item) => item.reason_replacement === reason);
              row.push(item ? item.value : 0);
            });
            excelData.push(row);
          });

          // Create a new workbook and worksheet
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(excelData);

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(wb, ws, "Replacement Reasons");

          // Generate the Excel file as an ArrayBuffer
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

          // Create a Blob from the ArrayBuffer
          const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          // Create a download link and trigger the download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "replacement_reasons.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Clean up
          URL.revokeObjectURL(link.href);
        } else {
          console.warn("No data found for XLSX export.");
        }
      } catch (error) {
        console.error("Error downloading replacement reasons data:", error);
        alert("Error: " + error.message);
      }
    },

    async downloadRegion() {
      try {
        const url = "api/distribution/replacement/by-region";

        const start = this.dateRange.start;
        const end = this.dateRange.end;
        let params = { start, end };

        if (this.selectedRegion) {
          params.region = this.selectedRegion.split(" - ")[0];
        }
        if (this.selectedManufactureYear && this.selectedManufactureYear !== "All Years") {
          params.manufacture_year = this.selectedManufactureYear;
        }

        const res = await axios.get(url, {
          params: { ...params },
        });

        if (res.data && res.data.data) {
          const data = res.data.data;
          const worksheetData = [];

          // Prepare headers
          let headers = ["Region Name"];
          const firstRegion = Object.values(data)[0];
          if (firstRegion) {
            headers = headers.concat(firstRegion.map((item) => item.bulan));
          }
          worksheetData.push(headers);

          // Prepare rows
          for (const [region, values] of Object.entries(data)) {
            const row = [region];
            const monthlyData = values.map((v) => v.value);
            worksheetData.push(row.concat(monthlyData));
          }

          // Create a worksheet and workbook
          const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Region Data");

          // Export to XLSX
          XLSX.writeFile(workbook, `region_data_replacement_id.xlsx`);
        } else {
          console.warn("No data found for XLSX export.");
        }
      } catch (error) {
        console.error("Error downloading region data:", error);
      }
    },
    downloadDealerExcel() {
      // Determine which years to include based on the selection
      let yearsToInclude = [];
      if (!this.dealerSelectedYear || this.dealerSelectedYear === "All Year") {
        yearsToInclude = ["2024", "2023", "2022", "<=2021", "Unknown"];
      } else if (this.dealerSelectedYear === "<=2021") {
        yearsToInclude = ["<=2021"];
      } else if (this.dealerSelectedYear === "Unknown") {
        yearsToInclude = ["Unknown"];
      } else {
        yearsToInclude = [this.dealerSelectedYear];
      }

      // Create the header
      const header = ["Dealer Name", ...yearsToInclude];

      // Prepare the data
      const data = Object.entries(this.filteredDealerData).map(([dealerName, dealerData]) => {
        const row = { "Dealer Name": dealerName };
        yearsToInclude.forEach((year) => {
          if (year === "<=2021") {
            row[year] = this.getDealerValueForOlderYears(dealerData);
          } else if (year === "Unknown") {
            row[year] = this.getDealerValueForYear(dealerData, "Unknown");
          } else {
            row[year] = this.getDealerValueForYear(dealerData, year);
          }
        });
        return row;
      });

      // Create a new workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data, { header });

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Dealer Replacement Data");

      // Generate the Excel file as an ArrayBuffer
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // Create a Blob from the ArrayBuffer
      const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "replacement_by_dealer.xlsx";
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
    },
    resetPagination() {
      this.dealerCurrentPage = 1;
    },

    async fetchSegmentOptions() {
      try {
        const response = await axios.get("/api/master/segment");
        if (response.data.status === "success") {
          this.segmentOptions = response.data.data.map((item) => item.segment);
        } else {
          console.error("Error fetching segment options:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching segment options:", error);
      }
    },

    fetchSegmentData() {
      this.isLoadingSegment = true; // Show loading indicator
      const params = {
        start: this.dateRange.start,
        end: this.dateRange.end,
      };

      if (this.selectedSegments.length > 0) {
        params.segment = this.selectedSegments.join(",");
      }

      if (this.segmentSelectedYear && this.segmentSelectedYear !== "All Year") {
        params.manufacture_year = this.segmentSelectedYear;
      }

      this.$http
        .get("/api/distribution/replacement/by-segment", { params })
        .then((response) => {
          if (response.data.status === "success") {
            this.segmentData = response.data.data;
            this.$refs.segmentChart.updateChartData(response.data.data);
            this.isLoadingSegment = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching segment data:", error);
        });
    },
    downloadSegmentExcel() {
      console.log("Download function triggered");
      try {
        // Check if we have data to export
        if (!this.chartData || this.chartData.length === 0) {
          console.error("No data available to export");
          alert("No data available to export");
          return;
        }

        console.log("Preparing data for export", this.chartData);

        // Prepare the data
        const data = this.chartData.map((item) => ({
          Date: item.date instanceof Date ? item.date.toISOString().split("T")[0] : item.date,
          Value: item.value,
        }));

        console.log("Formatted data for export", data);

        // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Segment Data");

        // Generate the Excel file as an ArrayBuffer
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        console.log("Excel buffer created", excelBuffer);

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        console.log("Blob created", blob);

        // Create a download link and trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "replacement_segment_data.xlsx";
        console.log("Download link created", link.href);

        // Append the link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log("Download initiated");

        // Clean up
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error in downloadSegmentExcel:", error);
        alert("An error occurred while trying to download the data. Please check the console for more information.");
      }
    },
  },
};
</script>

<style>
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: none;
}

.replacement-production-year {
  min-height: 625px;
}

.card-table {
  min-height: 300px;
}

.card-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.form-select,
.btn {
  font-size: 0.875rem;
}

.bi-download {
  margin-left: 0.25rem;
}

.action-header {
  width: 1%;
  /* This forces the column to be as narrow as possible */
  white-space: nowrap;
  color: #4b4b4b;
  background-color: #ffffff;
  text-align: left;
}

.action-body {
  width: 1%;
  /* This forces the column to be as narrow as possible */
  white-space: nowrap;
  color: #4b4b4b;
  background-color: #ffffff;
  border-top: none;
  border-bottom: none;
  text-align: left;
}

.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
}

.hso-download-button {
  margin-top: 12px;
  height: 40px;
  margin-top: 0px;
  color: #2f2f2f;
  background-color: #ffffff;
  box-shadow: none;
  width: 100%;
  white-space: nowrap;
}

.hso-table-header {
  color: #4b4b4b;
  background-color: #ffffff;
  text-align: left;
}

.hso-table-body {
  border-bottom: none;
  border-top: none;
  text-align: left;
}

.hso-table-pagination {
  color: #cbcbcb;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.tooltip-content {
  font-size: 0.9em;
  color: #666;
}
</style>
<template>
	<div id="replacementProductionYearPieChart" style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";

am4core.useTheme(am4themes_animated);

export default {
	name: "PieChart",
	props: {
		startDate: {
			type: String,
			required: true,
		},
		endDate: {
			type: String,
			required: true,
		},
		selectedYear: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			chart: null,
			colorMap: {
				'2024': '#F2BC80',
				'2023': '#4E80ED',
				'2022': '#55B586',
				'<=2021': '#C2B6F6',
				'Unknown': '#F6B6EB'
			}
		};
	},
	methods: {
		async fetchChartData() {
			try {
				const response = await axios.get("/api/distribution/replacement/by-production-year", {
					params: {
						start: this.startDate,
						end: this.endDate,
						manufacture_year: this.selectedYear,
					},
				});
				if (response.data && response.data.data) {
					// Transform 'Others' to '<=2021' in the data
					const transformedData = response.data.data.map(item => ({
						...item,
						year_group: item.year_group === 'Others' ? '<=2021' : item.year_group
					}));
					this.createPieChart(transformedData);
				}
			} catch (error) {
				console.error("Error fetching chart data:", error);
			}
		},
		createPieChart(data) {
			if (this.chart) {
				this.chart.dispose();
			}

			let chart = am4core.create("replacementProductionYearPieChart", am4charts.PieChart);
			chart.data = data;

			chart.innerRadius = am4core.percent(60);

			let pieSeries = chart.series.push(new am4charts.PieSeries());
			pieSeries.dataFields.value = "value";
			pieSeries.dataFields.category = "year_group";

			// Assign colors based on year_group
			pieSeries.slices.template.adapter.add("fill", (fill, target) => {
				if (target.dataItem && target.dataItem.category) {
					return am4core.color(this.colorMap[target.dataItem.category] || '#F6B6EB');
				}
				return fill;
			});

			pieSeries.labels.template.disabled = true;
			pieSeries.ticks.template.disabled = true;

			let total = data.reduce((sum, item) => sum + item.value, 0);

			// Find the highest percentage
			let highestPercentage = 0;
			let highestCategory = "";
			data.forEach(item => {
				let percentage = (item.value / total) * 100;
				if (percentage > highestPercentage) {
					highestPercentage = percentage;
					highestCategory = item.year_group;
				}
			});

			// Create a label in the center of the chart
			let innerLabel = chart.seriesContainer.createChild(am4core.Label);
			innerLabel.text = `[bold]${highestCategory}[/]\n${highestPercentage.toFixed(2)}%`;
			innerLabel.horizontalCenter = "middle";
			innerLabel.verticalCenter = "middle";
			innerLabel.fontSize = 20;
			innerLabel.textAlign = "middle";

			// Add a legend
			chart.legend = new am4charts.Legend();
			chart.legend.position = "bottom";
			chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#.0')}%";

			// Remove commas from year labels in the legend
			chart.legend.labels.template.adapter.add("text", function (text, target) {
				if (target.dataItem && target.dataItem.dataContext) {
					let yearGroup = target.dataItem.dataContext.year_group;
					return yearGroup ? yearGroup.toString().replace(/,/g, '') : text;
				}
				return text;
			});

			// Ensure 'year_group' is shown without commas in tooltips as well
			pieSeries.slices.template.tooltipText = "{category.formatNumber('#')}: {value} ({value.percent.formatNumber('#.0')}%)";

			chart.hiddenState.properties.opacity = 0;
			this.chart = chart;
		},

		updateChart() {
			this.fetchChartData();
		},
	},
	watch: {
		startDate() {
			this.updateChart();
		},
		endDate() {
			this.updateChart();
		},
		selectedYear() {
			this.updateChart();
		},
	},
	mounted() {
		this.fetchChartData();
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
};
</script>

<style scoped>
#replacementProductionYearPieChart {
	width: 100%;
	height: 500px;
}
</style>